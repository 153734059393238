import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import vendorReducer from './reducers/vendorReducer';
import userProfileReducer from './reducers/userProfileReducer';
import platformReducer from './reducers/platformReducer';
import apikeysReducer from './reducers/apikeyReducer'
import taxesReducer from './reducers/taxesReducer'
import addonsReducer from './reducers/addonsReducer'
import resourcesReducer from './reducers/resourcesReducer'
import activitiesReducer from './reducers/activitiesReducer'
import packagesReducer from './reducers/packagesReducer'
import sessionsReducer from './reducers/sessionReducer'
import ticketsReducer from './reducers/ticketsReducer'

const rootReducer = combineReducers({
    theme,
    auth,
    vendorReducer,
    userProfileReducer,
    platformReducer,
    apikeysReducer,
    taxesReducer,
    addonsReducer,
    resourcesReducer,
    activitiesReducer,
    packagesReducer,
    sessionsReducer,
    ticketsReducer
})

export default rootReducer

