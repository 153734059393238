import { UngroupOutlined, ContactsOutlined, UsergroupAddOutlined, ShopOutlined, FileProtectOutlined, KeyOutlined, MoneyCollectOutlined, AppstoreAddOutlined, FundViewOutlined } from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const adminNavTree = [
  {
    key: 'users',
    path: `users`,
    title: 'Users',
    icon: UsergroupAddOutlined,
    breadcrumb: false,
    submenu: []
  }, 
  {
    key: 'vendors',
    path: `vendors`,
    title: 'Vendors',
    icon: ContactsOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'platforms',
    path: `platforms`,
    title: 'Platforms',
    icon: ShopOutlined,
    breadcrumb: false,
    submenu: []
  },
]

export const vendorNavTree = [
  {
    key: 'vendors',
    path: `vendors`,
    title: 'Vendors',
    icon: ContactsOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'activity',
    path: `activity`,
    title: 'Activity',
    icon: FundViewOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'resources',
    path: `resources`,
    title: 'Resources',
    icon: UngroupOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'taxes',
    path: `taxes`,
    title: 'Taxes',
    icon: MoneyCollectOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'addons',
    path: `addons`,
    title: 'Addons',
    icon: AppstoreAddOutlined,
    breadcrumb: false,
    submenu: []
  },
]

export const platformNavTree = [
  {
    key: 'api-keys',
    path: `api-keys`,
    title: 'API keys',
    icon: KeyOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'whitelist-vendors',
    path: `whitelist-vendors`,
    title: 'Whitelist vendors',
    icon: FileProtectOutlined,
    breadcrumb: false,
    submenu: []
  },
]


