import React, { createContext, useContext, useState } from "react";

const StateContext = createContext();

export const StateProvider = ({ children }) => {
  const [value, setValue] = useState(localStorage.getItem("selectedOption"));
  const [loading, setLoading] = useState(false);

  const updateValue = (newValue) => {
    setLoading(true);
    setTimeout(() => {
      localStorage.setItem("selectedOption", newValue);
      setValue(newValue);
      setLoading(false);
    }, 500);
  };

  return (
    <StateContext.Provider value={{ value, loading, updateValue }}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useStateContext must be used within a StateProvider");
  }
  return context;
};
