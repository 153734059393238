/** @jsxImportSource @emotion/react */
import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TEMPLATE } from "constants/ThemeConstant";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import Logo from "../Logo";
import NavProfile from "../NavProfile";
import Header from "./Header";
import HeaderWrapper from "./HeaderWrapper";
import Nav from "./Nav";
import NavEdge from "./NavEdge";
import NavItem from "../NavItem";
import { toggleCollapsedNav, onMobileNavToggle } from "store/slices/themeSlice";
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import utils from "utils";
import { Select } from "antd";
import { getPlatformsAction } from "../../../store/actions/platformActions";
import { getVendorsAction } from "../../../store/actions/vendorActions";
import { useStateContext } from "../../../auth/StateProvider";

export const HeaderNav = (props) => {
  const { isMobile } = props;
  const { updateValue } = useStateContext();
  const [searchActive, setSearchActive] = useState(false);

  const dispatch = useDispatch();

  const platformsList = useSelector((state) => state.platformReducer).platforms;
  const vendorsList = useSelector((state) => state.vendorReducer).vendors;

  const { token } = useSelector((state) => state.auth);
  const navCollapsed = useSelector((state) => state.theme.navCollapsed);
  const mobileNav = useSelector((state) => state.theme.mobileNav);
  const navType = useSelector((state) => state.theme.navType);
  const headerNavColor = useSelector((state) => state.theme.headerNavColor);
  const currentTheme = useSelector((state) => state.theme.currentTheme);

  const [selectOptions, setSelectOptions] = useState();
  const [selectedOption, setSelectedOption] = useState(null);

  const [user, setUser] = useState();

  useEffect(() => {
    const fetchItems = async () => {
      if (token !== "") {
        const getUser = await JSON.parse(token);
        setUser(getUser);
        if (getUser.roleid === 3) {
          const idToken = localStorage.getItem("idToken");
          dispatch(getPlatformsAction(idToken));
        }
      }
    };
    fetchItems();
  }, [token]);

  useEffect(() => {
    if (platformsList.length > 0 && user && user.roleid === 3) {
      const newArray = platformsList.reduce((acc, item) => {
        acc.push({ value: item.platformid, label: item.name });
        return acc;
      }, []);
      setSelectOptions(newArray);
      const previousSelectedOption = localStorage.getItem("selectedOption");
      if (previousSelectedOption) {
        const userSelected = newArray.filter(
          (el) => el.value == previousSelectedOption
        );
        if (userSelected.length) setSelectedOption(userSelected[0].label);
        updateValue(userSelected[0].value);
      } else {
        localStorage.setItem("selectedOption", newArray[0].value);
        setSelectedOption(newArray[0].label);
        updateValue(newArray[0].value);
      }
    }
  }, [platformsList, user]);

  useEffect(() => {
    if (vendorsList.length > 0 && user && user.roleid === 2) {
      const newArray = vendorsList.reduce((acc, item) => {
        acc.push({ value: item.vendorid, label: item.name });
        return acc;
      }, []);
      setSelectOptions(newArray);
      const previousSelectedOption = localStorage.getItem("selectedOption");
      if (previousSelectedOption) {
        const userSelected = newArray.filter(
          (el) => el.value == previousSelectedOption
        );
        if (userSelected.length) setSelectedOption(userSelected[0].label);
        // updateValue(userSelected[0].value);
      } else {
        localStorage.setItem("selectedOption", newArray[0].value);
        setSelectedOption(newArray[0].label);
        // updateValue(newArray[0].value);
      }
    }
  }, [vendorsList, user]);

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      dispatch(toggleCollapsedNav(!navCollapsed));
    } else {
      dispatch(onMobileNavToggle(!mobileNav));
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP;
  const isDarkTheme = currentTheme === "dark";

  const navMode = useMemo(() => {
    if (!headerNavColor) {
      return utils.getColorContrast(isDarkTheme ? "#000000" : "#ffffff");
    }
    return utils.getColorContrast(headerNavColor);
  }, [isDarkTheme, headerNavColor]);

  const navBgColor = isDarkTheme
    ? TEMPLATE.HEADER_BG_DEFAULT_COLOR_DARK
    : TEMPLATE.HEADER_BG_DEFAULT_COLOR_LIGHT;

  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  useEffect(() => {
    if (!isMobile) {
      onSearchClose();
    }
  }, []);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    updateValue(selectedOption);
  };

  return (
    <Header
      isDarkTheme={isDarkTheme}
      headerNavColor={headerNavColor || navBgColor}
    >
      <HeaderWrapper isNavTop={isNavTop}>
        <Logo logoType={navMode} />

        <Nav navWidth={getNavWidth()}>
          <NavEdge left>
            {isNavTop && !isMobile ? null : (
              <NavItem onClick={onToggle} mode={navMode}>
                <div className="d-flex align-items-center">
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </div>
              </NavItem>
            )}
          </NavEdge>

          <NavEdge right>
            {user && user.roleid !== 1 && (
              <Select
                style={{
                  width: 200,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onChange={handleChange}
                options={selectOptions}
                value={selectedOption}
              />
            )}
            <NavProfile mode={navMode} user={user} />
          </NavEdge>
        </Nav>
      </HeaderWrapper>
    </Header>
  );
};

export default HeaderNav;
