import * as types from "../../constants/ApiConstant";

const initialState = {
  tickets: []
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.GET_TICKETS_SUCCESS: {
      return {
        ...state,
        tickets: actions.tickets
      };
    }
    case types.POST_TICKETS_SUCCESS: {
      return {
        ...state,
        tickets: actions.tickets
      };
    }
    default:
      return state;
  }
}