import * as types from "../../constants/ApiConstant";

const initialState = {
  sessions: [],
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.GET_SESSIONS_SUCCESS: {
      return {
        ...state,
        sessions: actions.sessions,
      };
    }
    case types.POST_SESSIONS_SUCCESS: {
      return {
        ...state,
        sessions: actions.sessions,
      };
    }
    case types.DELETE_SESSIONS_SUCCESS: {
      return {
        ...state,
        sessions: actions.sessions,
      };
    }
    default:
      return state;
  }
}
