import React from 'react'

export const publicRoutes = [
    {
        key: 'login',
        path: `login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
        meta: { title: 'Login' }
    },
    {
        key: 'register',
        path: `register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
        meta: { title: 'Register' }
    },
    {
        key: 'forgot-password',
        path: `forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
        meta: { title: 'Forgot password' }
    }
]

export const vendorRoutes = [
    {
        key: 'vendors',
        path: `vendors`,
        component: React.lazy(() => import('views/app-views/vendor/vendors')),
        meta: { title: 'Vendors' }
    },
    {
        key: 'taxes',
        path: `taxes`,
        component: React.lazy(() => import('views/app-views/vendor/taxes')),
        meta: { title: 'Taxes' }
    },
    {
        key: 'addons',
        path: `addons`,
        component: React.lazy(() => import('views/app-views/vendor/addons')),
        meta: { title: 'Addons' }
    },
    {
        key: 'resources',
        path: `resources`,
        component: React.lazy(() => import('views/app-views/vendor/resources')),
        meta: { title: 'Resources' }
    },
    {
        key: 'activity',
        path: `activity`,
        component: React.lazy(() => import('views/app-views/vendor/activity/activity')),
        meta: { title: 'Activity' }
    },
    {
        key: 'edit-profile',
        path: `edit-profile`,
        component: React.lazy(() => import('views/app-views/profile')),
        meta: { title: 'Edit profile' }
    },
]

export const platformRoutes = [
    {
        key: 'edit-profile',
        path: `edit-profile`,
        component: React.lazy(() => import('views/app-views/profile')),
    },
    {
        key: 'api-keys',
        path: `api-keys`,
        component: React.lazy(() => import('views/app-views/platform/api-keys')),
    },
    {
        key: 'whitelist-vendors',
        path: `whitelist-vendors`,
        component: React.lazy(() => import('views/app-views/platform/whitelist-vendors')),
    },
]

export const adminRoutes = [
    {
        key: 'users',
        path: `users`,
        component: React.lazy(() => import('views/app-views/admin/users')),
    },
    {
        key: 'vendors',
        path: `vendors`,
        component: React.lazy(() => import('views/app-views/admin/vendors')),
    },
    {
        key: 'platforms',
        path: `platforms`,
        component: React.lazy(() => import('views/app-views/admin/platforms')),
    },
]