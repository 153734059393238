import React, {useState} from "react";
import { Dropdown, Avatar, Modal, Form, Input, Button, message } from "antd";
import { useDispatch } from "react-redux";
import {
  EditOutlined,
  UserOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import NavItem from "./NavItem";
import Flex from "components/shared-components/Flex";
import { signOut } from "store/slices/authSlice";
import styled from "@emotion/styled";
import {
  FONT_WEIGHT,
  MEDIA_QUERIES,
  SPACER,
  FONT_SIZES,
} from "constants/ThemeConstant";
import { APP_PREFIX_PATH } from "../../configs/AppConfig";
import { Link } from "react-router-dom";
import FirebaseService from 'services/FirebaseService';

const Icon = styled.div(() => ({
  fontSize: FONT_SIZES.LG,
}));

const Profile = styled.div(() => ({
  display: "flex",
  alignItems: "center",
}));

const UserInfo = styled("div")`
  padding-left: ${SPACER[2]};

  @media ${MEDIA_QUERIES.MOBILE} {
    display: none;
  }
`;

const Name = styled.div(() => ({
  fontWeight: FONT_WEIGHT.SEMIBOLD,
}));

const Title = styled.span(() => ({
  opacity: 0.8,
}));

const MenuItem = (props) => (
  <Flex as={Link} to={props.path} alignItems="center" gap={SPACER[2]}>
    <Icon>{props.icon}</Icon>
    <span>{props.label}</span>
  </Flex>
);

const MenuItemSignOut = (props) => {
  const dispatch = useDispatch();

  const handleSignOut = () => {
    dispatch(signOut());
  };

  return (
    <div onClick={handleSignOut}>
      <Flex alignItems="center" gap={SPACER[2]}>
        <Icon>
          <LogoutOutlined />
        </Icon>
        <span>{props.label}</span>
      </Flex>
    </div>
  );
};

const MenuItemResetPassword = (props) => {
  const [visible, setVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    try {
      await FirebaseService.sendPasswordResetEmail(email);
      message.success("Password reset email sent!");
      setVisible(false);
      setEmail("");
    } catch (error) {
      message.error(error.message);
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setEmail("");
  };

  return (
    <>
      <div onClick={showModal}>
        <Flex alignItems="center" gap={SPACER[2]}>
          <Icon>
            <EditOutlined />
          </Icon>
          <span>{props.label}</span>
        </Flex>
      </div>
      <Modal
        title="Reset Password"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <Form name="reset_password" layout="vertical">
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const items = [
  {
    key: "Profile",
    label: <MenuItem path={`edit-profile`} label="Edit Profile" icon={<UserOutlined />} />,
    roles: [2, 3],
  },
  {
    key: "Reset password",
    label: <MenuItemResetPassword label="Reset password" />,
    roles: [1, 2, 3],
  },
  {
    key: "Sign Out",
    label: <MenuItemSignOut label="Sign Out" />,
    roles: [1, 2, 3],
  },
];

export const NavProfile = ({ mode, user }) => {
  const filteredItems = items.filter(item => item.roles.includes(user?.roleid));
  return (
    <Dropdown placement="bottomRight" menu={{ items: filteredItems }} trigger={["click"]}>
      <NavItem mode={mode}>
        <Profile>
          <Avatar
            style={{
              backgroundColor: "#87d068",
              verticalAlign: "middle",
            }}
            size="large"
            gap={4}
          >
            {user?.email
              .split(" ")
              .map((email) => email[0])
              .join("")
              .toUpperCase()}
          </Avatar>
          <UserInfo className="profile-text">
            <Name>{user?.email.match(/^([^@]*)@/)[1]}</Name>
            <Title></Title>
          </UserInfo>
        </Profile>
      </NavItem>
    </Dropdown>
  );
};

export default NavProfile;
