const FirebaseConfig = {
  apiKey: 'AIzaSyB7NyVY0JNb94R77h_tgoXrAwIPCQvR89I',
  authDomain: 'vendor-platform-pva.firebaseapp.com',
  databaseURL: 'https://emilus.firebaseio.com',
  projectId: 'vendor-platform-pva',
  storageBucket: 'vendor-platform-pva.appspot.com',
  messagingSenderId: '716129272601',
  appId: '1:716129272601:web:104fa44002802eb9ad7b88',
  measurementId: 'G-BCR9GJ2BTB'
};

export default FirebaseConfig

