import React, { Suspense, useEffect, useState } from "react";
import {
  // BrowserRouter as Router,
  Routes as RouterRoutes,
  Route,
  Navigate,
} from "react-router-dom";
import {
  vendorRoutes,
  platformRoutes,
  adminRoutes,
  publicRoutes,
} from "configs/RoutesConfig";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import AppRoute from "./AppRoute";
import { useSelector } from "react-redux";
import AppLayout from "../layouts/AppLayout";
import AuthLayout from "../layouts/AuthLayout";
import { ConfigProvider } from 'antd';
import Loading from 'components/shared-components/Loading';
import { lightTheme, darkTheme } from 'configs/ThemeConfig';
import { resources } from 'lang';
import useBodyClass from 'utils/hooks/useBodyClass';

const AllRoutes = () => {
  const { token } = useSelector((state) => state.auth);
  const [navTree, setNavTree] = useState([]);
  const [path, setPath] = useState("");
  const [isInitialRedirect, setIsInitialRedirect] = useState(true);

  useEffect(() => {
    if (token) {
      const getUser = JSON.parse(token);
      switch (getUser.roleid) {
        case 1:
          setNavTree(adminRoutes);
          setPath("users");
          break;
        case 2:
          setNavTree(vendorRoutes);
          setPath("vendors");
          break;
        case 3:
          setNavTree(platformRoutes);
          setPath("whitelist-vendors");
          break;
        default:
          setNavTree([]);
          setPath("");
          break;
      }
    } else {
      setNavTree([]);
      setPath("");
    }
    setIsInitialRedirect(true);
  }, [token]);

  const Layout = token ? AppLayout : AuthLayout;

  const locale = useSelector(state => state.theme.locale);

	const direction = useSelector(state => state.theme.direction);

	const currentTheme = useSelector(state => state.theme.currentTheme);

	const currentAppLocale = resources[locale];

	useBodyClass(`dir-${direction}`);

	const themeConfig = currentTheme === 'light' ? {...lightTheme} : {...darkTheme}

  return (
    <ConfigProvider
      theme={themeConfig}
      direction={direction}
      locale={currentAppLocale.antd}
    >
      <Suspense fallback={<Loading cover="content" />}>
        <Layout>
          <RouterRoutes>
            <Route path="/" element={<ProtectedRoute />}>
              {isInitialRedirect && path && (
                <Route
                  path="/"
                  element={<Navigate replace to={`/${path}`} />}
                />
              )}
              {navTree.map((route, index) => (
                <Route
                  key={route.key + index}
                  path={route.path}
                  element={
                    <AppRoute component={route.component} {...route.meta} />
                  }
                />
              ))}
              <Route path="*" element={<Navigate to={`/${path}`} replace />} />
            </Route>
            <Route path="/" element={<PublicRoute />}>
              {publicRoutes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <AppRoute
                      component={route.component}
                      blankLayout={true}
                      {...route.meta}
                    />
                  }
                />
              ))}
            </Route>
          </RouterRoutes>
        </Layout>
      </Suspense>
    </ConfigProvider>
  );
};

export default AllRoutes;
