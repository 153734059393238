import * as types from "../../constants/ApiConstant";

const initialState = {
  activities: []
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.GET_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        activities: actions.activities
      };
    }
    case types.POST_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        activities: actions.activities
      };
    }
    case types.DELETE_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        activities: actions.activities
      };
    }
    default:
      return state;
  }
}