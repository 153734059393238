import * as types from "../../constants/ApiConstant";

const initialState = {
  packages: [],
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.GET_PACKAGES_SUCCESS: {
      return {
        ...state,
        packages: actions.packages,
      };
    }
    case types.POST_PACKAGES_SUCCESS: {
      return {
        ...state,
        packages: actions.packages,
      };
    }
    case types.DELETE_PACKAGES_SUCCESS: {
      return {
        ...state,
        packages: actions.packages,
      };
    }
    default:
      return state;
  }
}
