// UserRole const
export const UserRole = {
    ADMIN: 'ADMIN',
    VENDOR: 'VENDOR',
    PLATFORM: 'PLATFORM',
};

export const GET_VENDORS_SUCCESS = "GET_VENDORS_SUCCESS";
export const GET_VENDORS_FAILURE = "GET_VENDORS_FAILURE";
export const GET_VENDOR_SUCCESS = "GET_VENDOR_SUCCESS";
export const GET_VENDOR_FAILURE = "GET_VENDOR_FAILURE";
export const POST_VENDOR_SUCCESS = "POST_VENDOR_SUCCESS"
export const POST_VENDOR_FAILURE = "POST_VENDOR_FAILURE";
export const DELETE_VENDOR_SUCCESS = "DELETE_VENDOR_SUCCESS"
export const DELETE_VENDOR_FAILURE = "DELETE_VENDOR_FAILURE"

export const GET_USER_PROFILES_SUCCESS = "GET_USER_PROFILES_SUCCESS"
export const GET_USER_PROFILES_FAILURE = "GET_USER_PROFILES_FAILURE"
export const DELETE_USER_PROFILE_SUCCESS = "DELETE_USER_PROFILE_SUCCESS"
export const DELETE_USER_PROFILE_FAILURE = "DELETE_USER_PROFILE_FAILURE"

export const GET_PLATFORMS_SUCCESS = "GET_PLATFORMS_SUCCESS";
export const GET_PLATFORM_SUCCESS = "GET_PLATFORM_SUCCESS";
export const GET_PLATFORMS_FAILURE = "GET_PLATFORMS_FAILURE";
export const GET_PLATFORM_FAILURE = "GET_PLATFORM_FAILURE";
export const POST_PLATFORM_SUCCESS = "POST_PLATFORM_SUCCESS"
export const POST_PLATFORM_FAILURE = "POST_PLATFORM_FAILURE";
export const DELETE_PLATFORM_SUCCESS = "DELETE_PLATFORM_SUCCESS"
export const DELETE_PLATFORM_FAILURE = "DELETE_PLATFORM_FAILURE"

export const GET_APIKEY_SUCCESS = "GET_APIKEY_SUCCESS";
export const GET_APIKEY_FAILURE = "GET_APIKEY_FAILURE";
export const POST_APIKEY_SUCCESS = "POST_APIKEY_SUCCESS"
export const POST_APIKEY_FAILURE = "POST_APIKEY_FAILURE";
export const DELETE_APIKEY_SUCCESS = "DELETE_APIKEY_SUCCESS"
export const DELETE_APIKEY_FAILURE = "DELETE_APIKEY_FAILURE"

export const GET_TAXES_SUCCESS = "GET_TAXES_SUCCESS";
export const GET_TAXES_FAILURE = "GET_TAXES_FAILURE";
export const POST_TAXES_SUCCESS = "POST_TAXES_SUCCESS"
export const POST_TAXES_FAILURE = "POST_TAXES_FAILURE";
export const DELETE_TAXES_SUCCESS = "DELETE_TAXES_SUCCESS"
export const DELETE_TAXES_FAILURE = "DELETE_TAXES_FAILURE"

export const GET_ADDONS_SUCCESS = "GET_ADDONS_SUCCESS";
export const GET_ADDONS_FAILURE = "GET_ADDONS_FAILURE";
export const POST_ADDONS_SUCCESS = "POST_ADDONS_SUCCESS"
export const POST_ADDONS_FAILURE = "POST_ADDONS_FAILURE";
export const DELETE_ADDONS_SUCCESS = "DELETE_ADDONS_SUCCESS"
export const DELETE_ADDONS_FAILURE = "DELETE_ADDONS_FAILURE"

export const GET_RESOURCES_SUCCESS = "GET_RESOURCES_SUCCESS";
export const GET_RESOURCES_FAILURE = "GET_RESOURCES_FAILURE";
export const POST_RESOURCES_SUCCESS = "POST_RESOURCES_SUCCESS"
export const POST_RESOURCES_FAILURE = "POST_RESOURCES_FAILURE";
export const DELETE_RESOURCES_SUCCESS = "DELETE_RESOURCES_SUCCESS"
export const DELETE_RESOURCES_FAILURE = "DELETE_RESOURCES_FAILURE"

export const GET_ACTIVITIES_SUCCESS = "GET_ACTIVITIES_SUCCESS";
export const GET_ACTIVITIES_FAILURE = "GET_ACTIVITIES_FAILURE";
export const POST_ACTIVITIES_SUCCESS = "POST_ACTIVITIES_SUCCESS"
export const POST_ACTIVITIES_FAILURE = "POST_ACTIVITIES_FAILURE";
export const DELETE_ACTIVITIES_SUCCESS = "DELETE_ACTIVITIES_SUCCESS"
export const DELETE_ACTIVITIES_FAILURE = "DELETE_ACTIVITIES_FAILURE"

export const GET_PACKAGES_SUCCESS = "GET_PACKAGES_SUCCESS";
export const GET_PACKAGES_FAILURE = "GET_PACKAGES_FAILURE";
export const POST_PACKAGES_SUCCESS = "POST_PACKAGES_SUCCESS"
export const POST_PACKAGES_FAILURE = "POST_PACKAGES_FAILURE";
export const DELETE_PACKAGES_SUCCESS = "DELETE_PACKAGES_SUCCESS"
export const DELETE_PACKAGES_FAILURE = "DELETE_PACKAGES_FAILURE"

export const GET_SESSIONS_SUCCESS = "GET_SESSIONS_SUCCESS";
export const GET_SESSIONS_FAILURE = "GET_SESSIONS_FAILURE";
export const POST_SESSIONS_SUCCESS = "POST_SESSIONS_SUCCESS"
export const POST_SESSIONS_FAILURE = "POST_SESSIONS_FAILURE";
export const DELETE_SESSIONS_SUCCESS = "DELETE_SESSIONS_SUCCESS"
export const DELETE_SESSIONS_FAILURE = "DELETE_SESSIONS_FAILURE"

export const GET_TICKETS_SUCCESS = "GET_TICKETS_SUCCESS";
export const GET_TICKETS_FAILURE = "GET_TICKETS_FAILURE";
export const POST_TICKETS_SUCCESS = "POST_TICKETS_SUCCESS"
export const POST_TICKETS_FAILURE = "POST_TICKETS_FAILURE";
export const DELETE_TICKETS_SUCCESS = "DELETE_TICKETS_SUCCESS"
export const DELETE_TICKETS_FAILURE = "DELETE_TICKETS_FAILURE"