import React from "react";
import { Provider } from "react-redux";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import store from "./store";
import history from "./history";
import AllRoutes from "./routes";
import { THEME_CONFIG } from "./configs/AppConfig";
import "./lang";
import { AuthProvider } from "../src/auth/AuthContext";
import { StateProvider } from "./auth/StateProvider";
import { BrowserRouter } from "react-router-dom";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <AuthProvider>
          <StateProvider>
          <BrowserRouter history={history}>
            <ThemeSwitcherProvider
              themeMap={themes}
              defaultTheme={THEME_CONFIG.currentTheme}
              insertionPoint="styles-insertion-point"
            >
              <AllRoutes />
            </ThemeSwitcherProvider>
            </BrowserRouter>
          </StateProvider>
        </AuthProvider>
      </Provider>
    </div>
  );
}

export default App;
